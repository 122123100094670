import { setupFromConfig } from 'utils/registry'
import ApiClient from 'api-client/ApiClient'

import { clientActions } from 'core/client/clientReducers'
import store from 'app/store'
import { GlobalPreferences } from 'app/constants'
import { preferencesActions } from 'core/session/preferencesReducers'
import { themeActions } from 'core/session/themeReducers'
import Watchdog from 'core/watchdog'
import config from 'app-config'

const { dispatch } = store

setupFromConfig(config)

if (config.apiHost === undefined) {
  throw new Error('config.js does not contain "apiHost"')
}

// make sure the app starts in its default state
dispatch(
  clientActions.updateFrameValues({
    sidebarState: 'expanded',
    sidebarPane: 'default',
  }),
)

// Initialize ApiClient singleton
ApiClient.init({ keystoneEndpoint: `${config.apiHost}/keystone` })

// Start the watchdog heartbeat
Watchdog.init(1000, dispatch)
window.addEventListener('beforeunload', () => Watchdog.getInstance().destroy())

const loadTheme = async () => {
  const { preferenceStore } = ApiClient.getInstance()
  try {
    const response = await preferenceStore.getGlobalPreference(GlobalPreferences.Theme)
    const customTheme = JSON.parse(response.value)

    if (!customTheme) {
      return
    }

    dispatch(
      preferencesActions.updateLogo({
        logoUrl: customTheme.logoUrl,
        logoSrc: customTheme.logoSrc,
        logoFileName: customTheme.logoFileName,
      }),
    )

    // if (customTheme.headerColor || customTheme.sidenavColor) {
    //   dispatch(themeActions.updateThemeComponent(generateThemeUpdatePayload(customTheme)))
    // }
    dispatch(themeActions.updateGlobalTheme(customTheme))
  } catch (err) {
    // Reset the prefs if store not available
    // @ts-ignore
    dispatch(preferencesActions.resetGlobalPrefs())
  }
}

// Load the theme once the api client is instantiated
loadTheme()
